import Image from 'next/image'
import { useRouter } from 'next/router'

const DashboardCrossSell: React.FC<{}> = ({}) => {
  const router = useRouter()
  const dashboardUrl = `${process.env.NEXT_PUBLIC_DASHBOARD_DOMAIN}/`
  return (
    <div
      className='w-full min-h-[72px] rounded-lg flex items-center shadow-lg p-2 gap-2 bg-white cursor-pointer'
      onClick={() => router.push(dashboardUrl)}
    >
      <div className='shrink-0 bg-orange h-[52px] w-[52px] rounded-[16px] p-3'>
        <Image src='/icons/ic-dashboard.svg' alt='Mini Dashboard Logo' width={52} height={52} />
      </div>
      <div className='flex flex-col'>
        <span className='font-bold leading-none mb-1'>Dashboard Akun</span>
        <span className='text-xs font-light'>Cek progres status pesananmu di sini</span>
      </div>
      <Image
        src='/icons/ic-chevron-right.svg'
        alt='Chevron Right Logo'
        width={8}
        height={13}
        className='ml-auto'
      />
    </div>
  )
}

export default DashboardCrossSell
