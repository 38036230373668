import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { CartContext } from 'providers/CartProvider'
import { useContext } from 'react'
import WifAccountDropdown from './WifAccountDropdown'

const TextileRightAction: React.FC<{}> = ({}) => {
  const { cartCount } = useContext(CartContext)
  return (
    <div className='flex items-center shrink-0 gap-6'>
      <Link href='/explore' className='flex shrink-0 gap-1 items-center text-sm font-semibold'>
        <Image src='/icons/ic-explore.svg' alt='Explore Icon' width={32} height={32} />
        EXPLORE
      </Link>

      <Link href='/cart' className='flex shrink-0 relative cursor-pointer'>
        <Image src='/icons/ic-bag-wc.svg' alt='order' width={30} height={30} />
        {!!cartCount && (
          <span
            className={clsx(
              'absolute flex items-center justify-center  rounded-[4px] p-[2px] w-auto min-w-[14px] leading-none top-1 right-[2px] text-[10px] bg-wif-orange text-white'
            )}
          >
            {cartCount > 99 ? `99+` : cartCount}
          </span>
        )}
      </Link>

      <WifAccountDropdown />
    </div>
  )
}

export default TextileRightAction
