import { decode, encode, TAlgorithm } from 'jwt-simple'
import {
  DecodeResult,
  EncodeResult,
  ExpirationStatus,
  PartialSession,
  Session,
} from './sessionConfig'

export const encodeSession = (secretKey: string, partialSession: PartialSession): EncodeResult => {
  const algorithm: TAlgorithm = 'HS512'
  // Determine when the token should expire
  const issued = Date.now()
  // Token lifetime in milliseconds
  const lifetime = Number(process.env.JWT_LIFETIME)
  const expires = issued + lifetime
  const session: Session = {
    ...partialSession,
    issued: issued,
    expires: expires,
  }

  return {
    token: encode(session, secretKey, algorithm),
    issued: issued,
    expires: expires,
  }
}

export const decodeSession = (secretKey: string, tokenString: string): DecodeResult => {
  const algorithm: TAlgorithm = 'HS512'

  let result: Session

  try {
    result = decode(tokenString, secretKey, false, algorithm)
  } catch (_e) {
    const e = _e as Error

    // These error strings can be found here:
    // https://github.com/hokaccha/node-jwt-simple/blob/c58bfe5e5bb049015fcd55be5fc1b2d5c652dbcd/lib/jwt.js
    if (e.message === 'No token supplied' || e.message === 'Not enough or too many segments') {
      return {
        type: 'invalid-token',
        session: null,
      }
    }

    if (e.message === 'Signature verification failed' || e.message === 'Algorithm not supported') {
      return {
        type: 'integrity-error',
        session: null,
      }
    }

    if (e.message.indexOf('Unexpected token') === 0) {
      return {
        type: 'invalid-token',
        session: null,
      }
    }

    throw e
  }

  return {
    type: 'valid',
    session: result,
  }
}

export const checkExpirationStatus = (token: Session): ExpirationStatus => {
  const now = Date.now()

  if (token.expires > now) return 'active'

  // Find the timestamp for the end of the token's grace period
  const gracePeriod = Number(process.env.JWT_GRACE_PERIOD)
  const gracePeriodAfterExpiration = token.expires + gracePeriod

  if (gracePeriodAfterExpiration > now) return 'grace'

  return 'expired'
}
