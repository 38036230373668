import Image from 'next/image'
import Link from 'next/link'

interface BannerSectionProps {
  imgSrc: string
  alt: string
  id: string
  href?: string
  height?: number
  width?: number
}

const BannerSection: React.FC<BannerSectionProps> = ({
  imgSrc,
  alt,
  id,
  href,
  width = 428,
  height = 118,
}) => {
  const image = (
    <Image
      src={imgSrc || '/icons/ic-img-placeholder.svg'}
      width={width}
      height={height}
      alt={alt}
      sizes='100vw'
      style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
      data-test={`pcp-banner-${id}`}
      priority
    />
  )
  return !!href ? (
    <div className='cursor-pointer'>
      <Link href={href}>{image}</Link>
    </div>
  ) : (
    <>{image}</>
  )
}

export default BannerSection
