import clsx from 'clsx'
import { useTranslation } from 'next-i18next'

interface CategorySelectorItemProps {
  index: number
  title: string
  active?: boolean
  iconSrc: string
  onSelectCategory: () => void
}

const CategorySelectorItem: React.FC<CategorySelectorItemProps> = ({
  index,
  title,
  active = false,
  iconSrc,
  onSelectCategory,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={clsx([
        'grow-0 shrink-0 w-full cursor-pointer text-center flex gap-2 items-center border rounded-full p-2 md:px-6 md:py-3 max-w-fit min-w-[92px] border-wif-neutral-40',
      ])}
      onClick={onSelectCategory}
      data-test={`category-selector-${index}`}
    >
      <div
        className={clsx([
          'font-bold text-xs md:text-base whitespace-normal w-full',
          active && 'text-wif-orange',
        ])}
        data-test={`category-selector-title-${index}`}
      >
        {t(`explore:${title}`)}
      </div>
    </div>
  )
}

export default CategorySelectorItem
