import Loading from '@/components/Loading'
import { useRouter } from 'next/router'
import { TabItems } from 'types'
import TabBarItem from '../TabBarItem/TabBarItem'

export type TabBarProps = {
  withAccount?: boolean
  isLoggedIn?: boolean
  name?: string
  navItems: TabItems[]
}

const TabBar: React.FC<TabBarProps> = ({
  withAccount = false,
  isLoggedIn = false,
  name = '',
  navItems,
}) => {
  const router = useRouter()
  if (!router.isReady) return <Loading />
  const { pathname } = router
  return (
    <nav
      className='fixed left-0 right-0 bottom-0 max-w-[500px] md:max-w-full w-full mx-auto z-30 bg-white'
      id='tabbar'
    >
      <div className='h-[70px] flex flex-row gap-6 justify-center items-center px-6 shadow-[0_-5px_20px_0px_rgba(0,0,0,0.05)]'>
        {navItems.map(({ title, href, key, normalIcon, activeIcon }) => {
          const isActive = pathname === href
          return (
            <TabBarItem
              title={title}
              href={href}
              key={key}
              normalIcon={normalIcon}
              activeIcon={activeIcon}
              active={isActive}
            />
          )
        })}

        {withAccount && (
          <TabBarItem
            title={isLoggedIn ? name : 'Login'}
            href={isLoggedIn ? '/account' : '/login'}
            key={isLoggedIn ? 'accounts' : 'login'}
            normalIcon='/icons/ic-profile_32x32.svg'
            activeIcon='/icons/ic-profile_32x32-active.svg'
            active={isLoggedIn ? pathname === '/account' : pathname === '/login'}
          />
        )}
      </div>
    </nav>
  )
}

export default TabBar
