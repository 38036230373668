import { IncomingMessage } from 'http'
import * as cookie from 'cookie'

import type { UserQuery } from 'shopify/api'
import { UserDocument } from 'shopify/api'
import { shopifyGraphqlClient } from '@/lib/shopifyGraphql'
import { encodeSession } from '@/lib/jwt'

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export const findOne = (haystack: any[], arr: any[]) => {
  return arr.some((v) => haystack.includes(v))
}

export const getCategories = () => {
  return [
    {
      title: 'categories.all_categories',
      iconSrc: '/images/categories/cat-all.png',
    },
    {
      title: 'categories.chiffon_or_crepe',
      iconSrc: '/images/categories/cat-chiffon.png',
      decodedQueryParam: 'Chiffon or Crepe',
    },
    {
      title: 'categories.cotton_woven',
      iconSrc: '/images/categories/cat-cotton.png',
      decodedQueryParam: 'Cotton Woven',
    },
    {
      title: 'categories.knit',
      iconSrc: '/images/categories/cat-knit.png',
      decodedQueryParam: 'Knit',
    },
    {
      title: 'categories.linen_or_rami',
      iconSrc: '/images/categories/cat-linen.png',
      decodedQueryParam: 'Cotton Linen',
    },
    {
      title: 'categories.rayon_woven',
      iconSrc: '/images/categories/cat-rayon.png',
      decodedQueryParam: 'Rayon Woven',
    },
    {
      title: 'categories.satin',
      iconSrc: '/images/categories/cat-satin.png',
      decodedQueryParam: 'Satin',
    },
    {
      title: 'categories.scuba',
      iconSrc: '/images/categories/cat-scuba.png',
      decodedQueryParam: 'Scuba or Neoprene',
    },
    {
      title: 'categories.synthetic_woven',
      iconSrc: '/images/categories/cat-synthetic.png',
      decodedQueryParam: 'Synthetic Woven',
    },
  ]
}

/** Get server token for One Platform (OP) for use to request to OP Backend */
export async function getOpServerToken(req: IncomingMessage): Promise<string> {
  const reqCookie = req.headers.cookie
  if (!reqCookie) return ''

  const cookies = cookie.parse(reqCookie)
  const shopifyToken = cookies['session-token']

  if (!shopifyToken) return ''

  const { data } = await shopifyGraphqlClient.query<UserQuery>({
    query: UserDocument,
    variables: { token: shopifyToken },
  })

  const email = data.customer?.email
  if (!email) return ''

  const session = encodeSession(process.env.JWT_SECRET_KEY!, {
    username: email,
    email: email,
    createdDate: Date.now(),
  })

  return session.token
}
