import clsx from 'clsx'
import { GraphQLErrorExtensions } from 'graphql'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { AuthContext } from 'providers/AuthProvider'
import { CartContext } from 'providers/CartProvider'
import { useContext, useEffect, useRef, useState } from 'react'
import { useLogoutUserMutation } from 'shopify/api'
import DashboardCrossSell from './Account/DashboardCrossSell'
import WifButton from './WifButton'
import Icon from './utilities/Icon'

const WifAccountDropdown: React.FC<{}> = ({}) => {
  const router = useRouter()
  const { authToken, removeTokens, userData } = useContext(AuthContext)
  const { removeShoppingCartCookie } = useContext(CartContext)
  const fullName = userData ? `${userData?.firstName} ${userData?.lastName}` : ''
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [logoutUser] = useLogoutUserMutation()
  const dropdown = useRef(null)

  const getInitials = (name: string) => {
    let names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }

  const logout = async () => {
    try {
      const { data } = await logoutUser({ variables: { token: authToken! } })
      if (
        data?.customerAccessTokenDelete &&
        data?.customerAccessTokenDelete?.userErrors.length < 1
      ) {
        removeShoppingCartCookie()
        removeTokens()
      }
    } catch (err: any | GraphQLErrorExtensions) {
      console.error(err && err?.message)
    }
  }

  useEffect(() => {
    // only add the event listener when the dropdown is opened
    if (!isOpen) return
    function handleClick(event: any) {
      // @ts-ignore
      if (dropdown.current && !dropdown.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    window.addEventListener('click', handleClick)
    // clean up
    return () => window.removeEventListener('click', handleClick)
  }, [isOpen])

  if (!userData?.id)
    return (
      <WifButton
        type='tertiary'
        id='web-login'
        text='Login'
        handleClick={() => {
          removeTokens()
          router.push(
            `/login?${router.pathname.includes('wif-maas') ? `from=${router.pathname}` : ''}`
          )
        }}
        customStyle='!w-[140px] !h-[40px]'
      />
    )
  return (
    <div className='flex flex-row justify-center items-center gap-8'>
      <div
        ref={dropdown}
        className='flex flex-row gap-2 justify-center items-center font-bold text-sm cursor-pointer hover:bg-[#F4F4F4] rounded-xl p-[5px]'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='flex w-[40px] h-[40px] xs:w-[48px] xs:h-[48px] overflow-hidden rounded-full bg-[#FFBC99] relative leading-none justify-center items-center '>
          {getInitials(fullName)}
        </div>
        <div className='max-w-[100px] overflow-ellipsis whitespace-nowrap overflow-hidden'>
          {userData?.firstName}
        </div>
        <div className={clsx(isOpen && 'rotate-180')}>
          <Icon iconSrc={'/icons/ic-chevron-down_24x24.svg'} width={16} height={16} alt='chevron' />
        </div>
        {isOpen && (
          <div
            className={clsx(
              'z-30 absolute top-[60px] xs:top-[80px] bg-white right-4 rounded-lg shadow-[0px_4px_14px_0px_rgba(0,0,0,0.15)]'
            )}
          >
            <div className='min-w-[360px] py-7 px-4 relative'>
              <DashboardCrossSell />

              <ul className='flex flex-col pt-5'>
                <li>
                  <Link href='/account' className='gap-2 flex items-center py-4'>
                    <Image src='/icons/ic-account.svg' alt='Account Icon' width={32} height={32} />
                    Akun
                  </Link>
                </li>
              </ul>

              <div className='flex items-center gap-2 mt-8 text-wif-orange' onClick={logout}>
                <Image
                  src='/icons/ic-logout-outline.svg'
                  alt='Logout Icon'
                  width={24}
                  height={24}
                />
                Keluar
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default WifAccountDropdown
