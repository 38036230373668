import NavBar from '@/components/NavBar'
import TabBar from '@/components/TabBar'
import clsx from 'clsx'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { AuthContext } from 'providers/AuthProvider'
import { PropsWithChildren, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { RightActionType } from 'types'
import { DEFAULT_TABBAR_LIST } from 'types/constants'
import AppContainer from '../AppContainer'

interface RootLayoutProps {
  from: string
  withTabBar?: boolean
  withLogo?: boolean
  backButton?: boolean
  title?: string
  description?: string
  searchPlaceholder?: string
  searchPageHref?: string
  bgColor?: string
  rightActions?: React.ReactNode
}

const RootLayout: React.FC<PropsWithChildren<RootLayoutProps>> = ({
  children,
  from,
  withTabBar = true,
  withLogo = true,
  backButton = false,
  title,
  description,
  searchPlaceholder,
  searchPageHref = '/search',
  bgColor = '',
  rightActions = null,
}) => {
  const { authToken, userData } = useContext(AuthContext)
  const router = useRouter()
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const renderRightActions = () => {
    const actions: RightActionType[] = []

    if (
      router.pathname === '/' ||
      router.pathname.includes('/explore') ||
      router.pathname.includes('/product/[slug]')
    ) {
      actions.push('order')
      actions.push('search')
    }

    return actions
  }
  return (
    <AppContainer>
      <Head>
        {title && <title>{title}</title>}
        {description && <meta name='description'>{description}</meta>}
      </Head>
      <NavBar
        from={from}
        withLogo={withLogo}
        backButton={backButton}
        searchPageHref={searchPageHref}
        searchPlaceholder={searchPlaceholder}
        webViewRightActions={rightActions}
        rightActions={renderRightActions()}
        search={router.pathname === '/'}
      />
      <div className={clsx('pt-[70px]', bgColor, isMobile ? 'pb-[70px]' : '')}>
        <div
          className={clsx(
            // 'md:px-[60px] lg:px-[100px]',
            withTabBar || isMobile ? 'min-h-[calc(100vh-130px)]' : 'min-h-[calc(100vh-70px)]'
          )}
        >
          {children}
        </div>
      </div>
      {withTabBar && (
        <TabBar
          isLoggedIn={!!authToken}
          name={userData?.firstName!}
          navItems={DEFAULT_TABBAR_LIST}
          withAccount
        />
      )}
    </AppContainer>
  )
}

export default RootLayout
