import Link from 'next/link'
import Icon from '../utilities/Icon'

interface TabBarItemProps {
  title: string
  href: string
  active: boolean
  normalIcon: string
  activeIcon: string
}

const TabBarItem = ({ title, href, active, normalIcon, activeIcon }: TabBarItemProps) => {
  let tabBarItemStyles = 'text-center text-xs flex flex-col w-1/4'
  if (active) tabBarItemStyles += ' text-wif-orange fill-wif-orange'
  return (
    <div className={tabBarItemStyles}>
      <Link
        href={href}
        passHref={true}
        className='whitespace-nowrap flex flex-col justify-center items-center'
      >
        <Icon
          active={active}
          iconSrc={normalIcon}
          activeIconSrc={activeIcon}
          alt={title}
          lazyLoad={false}
        />
        <span className='overflow-hidden text-ellipsis max-w-[120px]'>{title}</span>
      </Link>
    </div>
  )
}

export default TabBarItem
